import ErrorState from "./ErrorState";
import ServerInfo from "../models/ServerInfo";
import {List} from "immutable";
import ErrorItem from "../models/ErrorItem";

export const LICENSING_DASHBOARD = "licensing";
export const FIRMWARES_DASHBOARD = "firmwares";
export const AUTH_INFO_DASHBOARD = "auth_info";

export default interface DashboardState {
    readonly progress: boolean;
    readonly serverInfo: ServerInfo | null;
    readonly errorsList: List<ErrorItem>;
    readonly error: ErrorState | null;
}

export interface DashboardsState {
    readonly auth_info: DashboardState
    readonly licensing: DashboardState;
    readonly firmwares: DashboardState;
}
