import { handleActions } from "redux-actions";
import { reduceAxiosFail } from "../states/ErrorState";
import DashboardState, { DashboardsState } from "../states/DashboardState";
import { List } from "immutable";

const dashboardDefaultState: DashboardState = {
    progress: false,
    serverInfo: null,
    errorsList: List(),
    error: null
};

export const DashboardsReducer = handleActions<DashboardsState>({
    GET_LICENSING_INFO_SUCCESS: (state, action: any): DashboardsState => ({
        firmwares: state.firmwares,
        auth_info: state.auth_info,
        licensing: {
            ...state.licensing,
            serverInfo: action.payload.data,
            error: null
        }
    }),
    GET_LICENSING_INFO_FAIL: (state, action: any): DashboardsState => ({
        firmwares: state.firmwares,
        auth_info: state.auth_info,
        licensing: {
            ...state.licensing,
            serverInfo: null,
            error: reduceAxiosFail(state, action)
        }
    }),
    GET_AUTH_INFO_SUCCESS: (state, action: any): DashboardsState => ({
        licensing: state.licensing,
        firmwares: state.firmwares,
        auth_info: {
            ...state.auth_info,
            serverInfo: action.payload.data,
            error: null
        }
    }),
    GET_AUTH_INFO_FAIL: (state, action: any): DashboardsState => ({
        licensing: state.licensing,
        firmwares: state.firmwares,
        auth_info: {
            ...state.auth_info,
            serverInfo: null,
            error: reduceAxiosFail(state, action)
        }
    }),
    GET_FWU_INFO_SUCCESS: (state, action: any): DashboardsState => ({
        licensing: state.licensing,
        auth_info: state.auth_info,
        firmwares: {
            ...state.firmwares,
            serverInfo: action.payload.data,
            error: null
        }
    }),
    GET_FWU_INFO_FAIL: (state, action: any): DashboardsState => ({
        licensing: state.licensing,
        auth_info: state.auth_info,
        firmwares: {
            ...state.firmwares,
            serverInfo: null,
            error: reduceAxiosFail(state, action)
        }
    }),
    GET_ERRORS: (state, action: any): DashboardsState => {
        const type = action.payload.meta.type as string;
        return {
            ...state,
            [type]: {
                // @ts-ignore
                ...state[type],
                progress: true
            }
        };
    },
    GET_ERRORS_SUCCESS: (state, action: any): DashboardsState => {
        const type = action.meta.previousAction.payload.meta.type as string;
        return {
            ...state, [type]: {
                // @ts-ignore
                ...state[type],
                errorsList: action.payload.data,
                progress: false
            }
        };
    },
    GET_ERRORS_FAIL: (state, action: any): DashboardsState => {
        const type = action.meta.previousAction.payload.meta.type as string;
        return {
            ...state, [type]: {
                // @ts-ignore
                ...state[type],
                error: reduceAxiosFail(state, action), progress: false
            }
        };
    }
}, {
    licensing: dashboardDefaultState,
    firmwares: dashboardDefaultState,
    auth_info: dashboardDefaultState
});
